/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import {
  HeadingLayout,
  Button,
  Reference,
  Filter,
} from 'components/elements'

import BlogOverview from 'components/elements/BlogOverview'
import BlogContainer, { BlogConsumer, BlogButton } from 'components/shared/BlogContainer'

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout activeHeader="Blog">
      <SEO yoast={yoast} path={path} />

      <BlogContainer
        postType="blog"
        settings={{
          limit: 6
        }}
      >
        <BlogConsumer>
          {({ hasPosts, posts, showMoreButton, showLessButton }) => {
            return (
              <>
                <Filter fields={acf.banner} />

                <HeadingLayout direction="left-left" className="container mt-3" heading={acf.blok_1.background_title}>
                  <div>
                    {hasPosts && (
                      <BlogOverview posts={posts} />
                    )}

                    {!hasPosts && (
                      <ParseContent className="mt-5 pt-5" content={acf.meta.no_posts} />
                    )}

                    {showMoreButton || showLessButton ? (
                      <div className="text-center mt-5">
                        {showMoreButton && (
                          <Button className="mx-3">
                            <BlogButton>
                              {acf.meta.show_more_button}
                            </BlogButton>
                          </Button>
                        )}
                        {showLessButton && (
                          <Button className="mx-3">
                            <BlogButton increment={false}>
                              {acf.meta.show_less_button}
                            </BlogButton>
                          </Button>
                        )}
                      </div>
                    ) : null}
                  </div>
                </HeadingLayout>
              </>
            )
          }}
        </BlogConsumer>
      </BlogContainer>
        
      <div data-component="blog" className="py-lg-5 mt-5">
        <div className="pt-5">
          <HeadingLayout heading={acf.blok_3.background_title} direction="right">
            <Reference
              fields={acf.blok_3}
              direction="right"
            />
          </HeadingLayout>
        </div>
      </div>

      <div className="py-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        meta {
          show_more_button
          show_less_button
          no_posts
        }

        banner {
          description
        }

        blok_1 {
          background_title
        }

        blok_3 {
          background_title
          description
          link {
            title
            url
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
